var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "sticky-container background-inherit" }, [
      _c("span", { staticClass: "float-left" }, [
        _c("span", { staticClass: "ml-4" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-sm btn-primary text-white py-2",
              attrs: {
                "data-toggle": "modal",
                "data-target": "#editarMaterialModal",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("initEdit")
                },
              },
            },
            [_vm._v("Editar")]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "editarMaterialModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "modalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  { staticClass: "modal-title", attrs: { id: _vm.modalLabel } },
                  [_vm._v(_vm._s(_vm.modalTitle))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Cerrar",
                    },
                    on: { click: _vm.limpiarEditGlobal },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "container" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.materials, function (material, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "col-md-3 form-group card p-2",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "d-block",
                                attrs: { for: material.id },
                              },
                              [
                                _c("img", {
                                  staticClass: "mb-2",
                                  attrs: {
                                    src: "/storage/img/no-image.jpg",
                                    title: "No Imagen",
                                    alt: "No Imagen",
                                    width: "100px",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "d-block" }, [
                                  _vm._v(_vm._s(material.name)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.dataGlobalEdit[material.type].board,
                                    expression:
                                      "dataGlobalEdit[material.type].board",
                                  },
                                ],
                                staticClass:
                                  "form-control form-control-sm select2 w-100",
                                attrs: { name: material.id, id: material.id },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.dataGlobalEdit[material.type],
                                      "board",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "",
                                      disabled: "",
                                      selected: "",
                                    },
                                  },
                                  [_vm._v("Selección")]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.limpiarEditGlobal },
                  },
                  [_vm._v("Cerrar")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "button" } },
                  [_vm._v("Guardar cambios")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ml-3 mt-3 mb-1 subtitulo-color" }, [
      _c("strong", [_vm._v("Selección de Materiales")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }