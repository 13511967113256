var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table", staticStyle: { "font-size": "0.9em" } },
    [
      _c("thead", [
        _c("tr", [
          _c("th", {}, [_vm._v("#")]),
          _vm._v(" "),
          _c("th", {}, [_vm._v("Tipo")]),
          _vm._v(" "),
          _c("th", {}, [_vm._v("SubTipo")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-right", attrs: { width: "18%" } }, [
            _vm._v("Cantidad"),
          ]),
          _vm._v(" "),
          _c("th", [
            _c(
              "a",
              {
                staticClass: "btn btn-link",
                attrs: { href: "#", title: "Agregar" },
                on: {
                  click: function ($event) {
                    return _vm.addRowMTP(this.app.mtps.length - 1)
                  },
                },
              },
              [_c("i", { staticClass: "fas fa-plus" })]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.mtps, function (mtp, index) {
          return _c("tr", { attrs: { "track-by": "index" } }, [
            _c("td", [
              _vm._v("\n        " + _vm._s(index + 1) + "\n        "),
              _c("input", {
                attrs: { type: "hidden", name: "mtp_id[]" },
                domProps: { value: mtp.mtp_id },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: "mtp_producto_id[]" },
                domProps: { value: mtp.mtp_producto_id },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: mtp.mtp_tipo_id,
                      expression: "mtp.mtp_tipo_id",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "mtp_tipo_id[]", required: "" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          mtp,
                          "mtp_tipo_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.getSubtipoMTP(
                          index,
                          _vm.mtps[index].mtp_tipo_id
                        )
                      },
                    ],
                  },
                },
                _vm._l(_vm.mtp_tipos, function (tipo) {
                  return _c("option", { domProps: { value: tipo.value } }, [
                    _vm._v(_vm._s(tipo.label)),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: mtp.mtp_subtipo_id,
                      expression: "mtp.mtp_subtipo_id",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "mtp_subtipo_id[]", required: "" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        mtp,
                        "mtp_subtipo_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.mtp_subtipos[index], function (subtipo) {
                  return _c("option", { domProps: { value: subtipo.value } }, [
                    _vm._v(_vm._s(subtipo.label)),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: mtp.mtp_cantidad,
                    expression: "mtp.mtp_cantidad",
                  },
                ],
                staticClass: "form-control form-control-sm text-right",
                attrs: {
                  type: "number",
                  name: "mtp_cantidad[]",
                  value: "",
                  min: "1",
                  required: "",
                },
                domProps: { value: mtp.mtp_cantidad },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(mtp, "mtp_cantidad", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm.mtps.length > 1
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-link text-danger",
                      attrs: { href: "#", title: "Eliminar" },
                      on: {
                        click: function ($event) {
                          return _vm.removeRowMTP(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-minus" })]
                  )
                : _vm._e(),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }