<style scoped>
    label {
        display: flex;
        flex-direction: column;
        row-gap: 9px;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #00A881;
    }

    select {
        border: none;
        height: 45px;
        max-width: 390px;
        border-radius: 5px;
        background-color: #F5F7F8;
        padding-inline: 8px;
    }
</style>

<template>
    <label>
        {{label}}
        <select v-model="inputValue">
            <option value="" disabled>Seleccione</option>
            <option v-for="(option, i) in options" :key="i" :value="option">
                {{ option["label"] }}
            </option>
        </select>
    </label>
</template>

<script>
export default {
    props: ['value', 'placeholder', 'options', 'label'],
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
}
</script>