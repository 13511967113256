<style scoped>
.products-list {
  margin-top: 40px;
  max-width: 100%;
  overflow-y: scroll;
}
</style>

<template>
  <div class="row products-list">
    <product-item v-for="(product, i) in products" :key="i" :product="product" @click="handleProductClicked">
    </product-item>
  </div>
</template>

<script>
export default {
  props: ["products"],
  methods: {
    handleProductClicked(id) {
      this.$emit('product-clicked', id);
    }
  }
}
</script>