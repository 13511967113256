var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "aplication" } }, [
    _c("div", {}, [
      _c("div", { staticClass: "card product-item" }, [
        _c("img", {
          staticClass: "card-img-top",
          attrs: {
            src: _vm.asset(`/storage/modulos/${_vm.premium.image}`),
            alt: _vm.premium.name,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("p", { staticClass: "card-text description" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.premium.description) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text price" }, [
            _vm._v("$" + _vm._s(_vm.premium.price)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }