var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col col-12 col-md-6 col-lg-4 col-xl-3",
      on: { click: _vm.handleOnClick },
    },
    [
      _c("div", { staticClass: "card product-item" }, [
        _c("img", {
          staticClass: "card-img-top",
          attrs: {
            src: _vm.asset(`/storage/modulos/${_vm.product.image}`),
            alt: _vm.product.name,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("p", { staticClass: "card-text description" }, [
            _vm._v("\n        " + _vm._s(_vm.product.description) + "\n      "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text price" }, [
            _vm._v("$" + _vm._s(_vm.toPrice(_vm.product.price))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }