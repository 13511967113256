import NoTransformable from './no-transformable'

export default class IndustriaFactory {
    createIndustria(tipo, priceList) {
        switch(tipo) {
            case 'No Transformable':
                return new NoTransformable(priceList);
            default:
                return undefined;
        }
    }
}