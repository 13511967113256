var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
    _c("div", { staticClass: "modal-dialog modal-dialog-centered modal-lg" }, [
      _vm.product
        ? _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "product-container" }, [
              _c("div", { staticClass: "product-header" }, [
                _c("div", { staticClass: "product-title" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-content" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.product.name))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.product.tipo))]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product-content" }, [
                _c("img", {
                  attrs: {
                    src: _vm.asset(`/storage/modulos/${_vm.product.image}`),
                    alt: _vm.product.name,
                  },
                }),
                _vm._v(" "),
                _vm.product.description
                  ? _c(
                      "div",
                      { staticClass: "product-info" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.product.description) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.product.height ||
                        _vm.product.width ||
                        _vm.product.depth
                          ? _c("p", [
                              _vm._v(
                                "\n              Dimensiones: " +
                                  _vm._s(_vm.product.height) +
                                  "x" +
                                  _vm._s(_vm.product.width) +
                                  "x" +
                                  _vm._s(_vm.product.depth) +
                                  "mm\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.madeIn
                          ? _c("p", [
                              _vm._v("Hecho en " + _vm._s(_vm.product.madeIn)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.guarantee
                          ? _c("p", [
                              _vm._v(
                                "Garantía: " + _vm._s(_vm.product.guarantee)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "price" }, [
                          _vm._v("$" + _vm._s(_vm.toPrice(_vm.product.price))),
                        ]),
                        _vm._v(" "),
                        _c("input-number", {
                          staticClass: "quantity",
                          attrs: { min: "1", value: _vm.quantity },
                          on: { change: _vm.handleOnChangeQuantity },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          { on: { click: _vm.handleOnAddProduct } },
                          [_vm._v("Agregar producto")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-square" }, [
      _c("img", {
        attrs: { src: "/storage/img/iconCuadrado.png", alt: "..." },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [
        _c("span", { staticClass: "material-symbols-outlined" }, [
          _vm._v(" close "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }