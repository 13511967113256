var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c("div", { staticClass: "header-container" }, [
      _c("div", { staticClass: "header-elements" }, [
        _c("div", { staticClass: "logo-content" }, [
          _c("img", { attrs: { src: _vm.image, alt: "Logo" } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-content" }, [
          _c("img", { attrs: { src: "/storage/img/search.png", alt: "" } }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "Buscar “Mesas para sala color negro”",
            },
            domProps: { value: _vm.inputValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.inputValue = $event.target.value
              },
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }