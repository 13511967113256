<style scoped>
.banner-image {
  background-image: url("/storage/img/banner.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 9px 78px 21px 94px;
}

h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 33.9718px;
  line-height: 46px;
  color: #ffffff;
}

h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 26.4225px;
  line-height: 36px;
  color: #ffffff;
}

.text-content {
  margin-bottom: 40px;
}

.input-container {
  width: 89%;
  position: relative;
}

.input-container img {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 20px;
  top: 11px;
}

input {
  background: #fafafa;
  border-radius: 67px;
  padding-left: 66px;
  width: 100%;
  height: 52px;
  border: none;
}

.input-content {
  display: flex;
  align-items: center;
}

.image-carrito {
  display: grid;
  place-content: center;
  background-color: #ffc93f;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 7%;
  position: relative;
  cursor: pointer;
}

.count {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 2px solid #ffb600;
  right: -25%;
  top: -25%;
}

.count span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12.7778px;
  line-height: 17px;
  color: #000000;
}
</style>

<template>
  <header>
    <div class="banner-image">
      <div class="text-content">
        <h1>Un nuevo punto de encuentro</h1>
        <h2>Let’s do it together!</h2>
        <img src="/storage/img/Logotipo.png" alt="" />
      </div>
      <div class="input-content">
        <div class="input-container">
          <img src="/storage/img/search.png" alt="" />
          <input
            type="text"
            placeholder="Buscar “Mesas para sala color negro”"
            v-model="inputValue"
          />
        </div>
        <div class="image-carrito" @click="handleCartClicked">
          <img src="/storage/img/carro-compras.png" alt="" />
          <div class="count">
            <span>{{ count }}</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["count", "value"],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleCartClicked() {
      this.$emit("cart-clicked");
    },
  },
};
</script>