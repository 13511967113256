import Industria from './industria';

export default class NoTransformable extends Industria {
    constructor(priceList) {
        super(priceList);
    }

    getProductPrice(product) {
        return this.priceList ? product.price / (1 - (this.priceList.brandProfit / 100)) : product.price;
    }
}