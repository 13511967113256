<style scoped>
    label, input {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
    }
    .input-container {
        display: flex;
        column-gap: 10px;
        align-items: center;
    }

    .input-container img {
        width: 47px;
        height: 47px;
    }

    .input-container label {
        display: flex;
        flex-direction: column;
        margin: 0;
        color: #121212;
        font-size: 16px;
        line-height: 22px;
    }

    .input-container label input {
        color: #545454;
        font-size: 20px;
        line-height: 27px;
        border: none;
    }
</style>

<template>
    <div class="input-container">
        <img src="/storage/img/input-icon.png" alt="icon">
        <label>
            {{label}}
            <input type="text" :placeholder="placeholder" v-model="inputValue">
        </label>
    </div>
</template>

<script>
export default {
    props: ['value', 'placeholder', 'label'],
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
}
</script>