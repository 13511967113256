var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-number-container" }, [
    _c("button", {
      staticClass: "control minus",
      attrs: { disabled: _vm.value === +_vm.min },
      on: { click: () => _vm.changeNumber(-1) },
    }),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "number", min: _vm.min },
      domProps: { value: _vm.value },
    }),
    _vm._v(" "),
    _c("button", {
      staticClass: "control plus",
      on: { click: () => _vm.changeNumber(1) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }