<style scoped>
.header-container {
  background: #e70a31;
  width: auto;
  height: 155px;
}
.header-elements {
  display: flex;
  align-items: center;
  margin-left: 71.33px;
}
.input-content {
  width: 100%;
  margin-top: 61px;
  position: relative;
}
.input-content img {
  position: absolute;
  left: 17.06px;
  top: 11.69px;
  bottom: 11.08px;
  border-radius: 0px;
}
input {
  background: #fafafa;
  border-radius: 67px;
  padding-left: 66px;
  width: 85%;
  margin-right: 215px;
  height: 52px;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #808080;
}
.logo-content img {
  width: 185.62px;
  height: 72.3px;
  margin-top: 41.14px;
  margin-right: 141px;
}
</style>
<template>
  <header>
    <div class="header-container">
      <div class="header-elements">
        <div class="logo-content">
          <img :src="image" alt="Logo" />
        </div>
        <div class="input-content">
          <img src="/storage/img/search.png" alt="" />
          <input
            type="text"
            placeholder="Buscar “Mesas para sala color negro”"
            v-model="inputValue"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["value", "image"],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>