<template>
    <div class="slider-container">
        <img class="slider-image" v-for="(image, i) in images" :key="i" :src="image" :alt="image" />
    </div>
</template>
<script>
export default {
    props: ["images"]
}
</script>
<style scoped>
    .slider-image {
        width: 100%;
        height: 508px;
        object-fit: cover;
    }
</style>