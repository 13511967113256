var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row products-list" },
    _vm._l(_vm.items, function (premium, i) {
      return _c("personalizado-product-item", {
        key: i,
        attrs: { premium: premium },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }