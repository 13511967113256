var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", [
    _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.inputValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      [
        _c("option", { attrs: { value: "", disabled: "" } }, [
          _vm._v("Seleccione"),
        ]),
        _vm._v(" "),
        _vm._l(_vm.options, function (option, i) {
          return _c("option", { key: i, domProps: { value: option } }, [
            _vm._v("\n            " + _vm._s(option["label"]) + "\n        "),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }