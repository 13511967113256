<template>
    <div id="aplication">
        <div class="">
            <div class="card product-item">
                <img :src="asset(`/storage/modulos/${premium.image}`)" class="card-img-top" :alt="premium.name" />
                <div class="card-body">
                    <p class="card-text description">
                        {{ premium.description }}
                    </p>
                    <p class="card-text price">${{ premium.price }}</p>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
export default {
    props: ["premium"],
    methods: {
        handleOnClick() {
            this.$emit("click", this.product.id);
        },
    },
}
</script>
<style scoped>
.product-item {
    box-shadow: none;
    max-width: 250px;
    height: 400px;
    margin-bottom: 41px;
    background: #f6f6f6;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    cursor: pointer;
}

.price {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #121212;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.card-img-top {
    max-height: 230px;
    width: auto;
    margin: 0 auto;
}

.card-body {
    background-color: #fff;
}

.botones-content {
    display: flex;
    justify-content: space-between;

}

button {
    border: none;
    width: 94.58px;
    height: 27.51px;
    background: #E70A31;
    border-radius: 84.4444px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10.1333px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
}


input {
    width: 40px;
    height: 28.91px;

}
</style>