<style scoped>
.cart-item-container {
  padding-inline: 20px;
  padding-block: 10px;
}

.cart-item-container.cart-item-large {
  display: grid;
  grid-template-columns: 1fr 206px;
}
.cart-item-resume {
  height: 200px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 24px 1fr 24px;
}

.cart-item-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #121212;
}

.price {
  grid-row: 1;
  grid-column: 1 / 2;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.remove {
  grid-row: 1;
  grid-column: 2 / 3;
  color: #f56868;
  padding: 0;
  width: max-content;
  justify-self: flex-end;
}

img {
  grid-row: 2;
  grid-column: 1 / 3;
  justify-self: center;
  width: 150px;
  height: auto;
}

.quantity {
  grid-row: 3;
  grid-column: 1 / 3;
  justify-self: center;
}
</style>

<template>
  <div class="cart-item-container" :class="size">
    <div class="cart-item-details" v-if="size === 'cart-item-large'">
      <p class="cart-item-name">{{ name }}</p>
      <p>{{ description }}</p>
      <p v-if="height || width || depth">
        Dimensiones: {{ height }}x{{ width }}x{{ depth }}mm
      </p>
      <p v-if="madeIn">Hecho en {{ madeIn }}</p>
      <p v-if="guarantee">Garantía: {{ guarantee }}</p>
    </div>
    <div class="cart-item-resume">
      <span class="price">${{ toPrice(price) }}</span>
      <button
        type="button"
        @click="handleOnRemove"
        class="close remove"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span class="material-symbols-outlined"> close </span>
      </button>
      <img :src="asset(`storage/modulos/${image}`)" :alt="name" />
      <input-number
        class="quantity"
        min="1"
        :value="quantity"
        @change="handleOnChangeQuantity"
      ></input-number>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "price",
    "quantity",
    "image",
    "name",
    "description",
    "height",
    "width",
    "depth",
    "madeIn",
    "guarantee",
    "size",
  ],
  methods: {
    handleOnRemove() {
      this.$emit("removed", this.id);
    },
    handleOnChangeQuantity(value) {
      this.$emit("edited", this.id, value);
    },
  },
};
</script>