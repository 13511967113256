var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c("div", { staticClass: "banner-image" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "input-content" }, [
        _c("div", { staticClass: "input-container" }, [
          _c("img", { attrs: { src: "/storage/img/search.png", alt: "" } }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "Buscar “Mesas para sala color negro”",
            },
            domProps: { value: _vm.inputValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.inputValue = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "image-carrito",
            on: { click: _vm.handleCartClicked },
          },
          [
            _c("img", {
              attrs: { src: "/storage/img/carro-compras.png", alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "count" }, [
              _c("span", [_vm._v(_vm._s(_vm.count))]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-content" }, [
      _c("h1", [_vm._v("Un nuevo punto de encuentro")]),
      _vm._v(" "),
      _c("h2", [_vm._v("Let’s do it together!")]),
      _vm._v(" "),
      _c("img", { attrs: { src: "/storage/img/Logotipo.png", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }