var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-item-container", class: _vm.size }, [
    _vm.size === "cart-item-large"
      ? _c("div", { staticClass: "cart-item-details" }, [
          _c("p", { staticClass: "cart-item-name" }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.description))]),
          _vm._v(" "),
          _vm.height || _vm.width || _vm.depth
            ? _c("p", [
                _vm._v(
                  "\n      Dimensiones: " +
                    _vm._s(_vm.height) +
                    "x" +
                    _vm._s(_vm.width) +
                    "x" +
                    _vm._s(_vm.depth) +
                    "mm\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.madeIn
            ? _c("p", [_vm._v("Hecho en " + _vm._s(_vm.madeIn))])
            : _vm._e(),
          _vm._v(" "),
          _vm.guarantee
            ? _c("p", [_vm._v("Garantía: " + _vm._s(_vm.guarantee))])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cart-item-resume" },
      [
        _c("span", { staticClass: "price" }, [
          _vm._v("$" + _vm._s(_vm.toPrice(_vm.price))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close remove",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
            on: { click: _vm.handleOnRemove },
          },
          [
            _c("span", { staticClass: "material-symbols-outlined" }, [
              _vm._v(" close "),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: _vm.asset(`storage/modulos/${_vm.image}`),
            alt: _vm.name,
          },
        }),
        _vm._v(" "),
        _c("input-number", {
          staticClass: "quantity",
          attrs: { min: "1", value: _vm.quantity },
          on: { change: _vm.handleOnChangeQuantity },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }