<style scoped>
.cart-container {
  width: 100%;
  border: 1px solid #d6d6d6;
  padding-inline-end: 8px;
}

.cart-container .cart-resume {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 0;
  border-bottom: 1px solid #d6d6d6;
}

.cart-container .cart-resume h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.cart-container .cart-resume .subtotal {
  text-align: center;
}

.cart-container .cart-resume .subtotal p {
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.cart-container .cart-resume .subtotal p.amount {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
}

.cart-container .cart-items {
  height: calc(100% - 200px);
  overflow-y: auto;
}

.cart-container .cart-items .cart-item:not(:first-child) {
  border-top: 0.5px solid #d6d6d6;
  border-bottom: 0.5px solid #d6d6d6;
}

button.save {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #00a881;
}

button.pay {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border-radius: 38px;
}

.cart-container.expanded {
  grid-column: 1/3;
  z-index: 1;
  justify-self: flex-end;
  width: 836px;
  background-color: #ffffff;
}
</style>

<template>
  <div class="cart-container" :class="expand && 'expanded'">
    <div class="cart-resume">
      <h6>Mi pedido</h6>

      <div class="subtotal">
        <p>Subtotal</p>
        <p class="amount">${{ toPrice(subtotal || 0) }}</p>
      </div>

      <button
        class="btn btn-link save"
        @click="handleOnSaveClicked"
        :disabled="isEmpty"
      >
        Guardar cotizaci&oacute;n
      </button>

      <button
        class="btn btn-canvas pay"
        @click="handleOnProceedToCheckoutClicked"
        :disabled="isEmpty"
      >
        Proceder al pago
      </button>
    </div>
    <div class="cart-items">
      <cart-item-component
        class="cart-item"
        v-for="(
          {
            id,
            price,
            quantity,
            image,
            name,
            description,
            height,
            width,
            depth,
            madeIn,
            guarantee,
          },
          i
        ) in products"
        :key="i"
        :id="id"
        :price="price"
        :quantity="quantity"
        :image="image"
        :name="name"
        :description="description"
        :height="height"
        :width="width"
        :depth="depth"
        :madeIn="madeIn"
        :guarantee="guarantee"
        @removed="handleOnProductRemoved"
        @edited="handleOnProductEdited"
        :size="expand ? 'cart-item-large' : 'cart-item-small'"
      ></cart-item-component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: Array,
    expand: Boolean,
  },
  computed: {
    subtotal() {
      return this.products?.reduce((a, b) => a + b.price * b.quantity, 0);
    },
    isEmpty() {
      return !this.products?.length;
    },
  },
  methods: {
    handleOnProductRemoved(id) {
      this.$emit("product-removed", id);
    },
    handleOnSaveClicked() {
      this.$emit("save");
    },
    handleOnProceedToCheckoutClicked() {
      this.$emit("proceed-to-checkout");
    },
    handleOnProductEdited(id, quantity) {
      const product = this.products.find((p) => p.id === id);

      this.$emit("product-edited", { ...product, quantity });
    },
  },
};
</script>