<style scoped>
    .tags-container {
        display: flex;
        flex-direction: column;
    }

    .label {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #00A881;
    }

    .tags-list {
        display: flex;
    }

    .tags-list label {
        padding: 10px 20px;
        background-color: #FFFFFF;
        border: 1px solid #c4c4c4;
        border-radius: 100px;
        margin-right: 20px;
    }

    .tags-list input[type="radio"]:checked + label {
        color: #FFFFFF;
        background-color: #1D518A;
        border-color: #1D518A;
    }
</style>

<template>
    <div class="tags-container">
        <label class="label">{{label}}</label>
        <div class="tags-list">
            <div>
                <input hidden id="Todas" type="radio" :name="label.toLowerCase()" :value="undefined" v-model="inputValue" checked>
                <label for="Todas">Todas</label>
            </div>
            <div v-for="(option, i) in options" :key=i>
                <input hidden :id="option['label']" type="radio" :name="label.toLowerCase()" :value="option" v-model="inputValue">
                <label :for="option['label']">{{option['label']}}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value', 'options', 'label'],
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
}
</script>