/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-colreorder-bs4");
require("datatables.net-responsive-bs4");
require("datatables.net-rowgroup-bs4");
require("datatables.net-rowreorder-bs4");
require("datatables.net-select-bs4");
require("select2");

import "datatables.net/js/jquery.dataTables.min.js";

import moment from "moment";

window.moment = moment;
//window.moment = require("moment");

window.holderjs = require("holderjs");

// Import perfect scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import "select2";
import "select2/dist/css/select2.css";

/* SweetAlert */
// window.swal = require('sweetalert2');
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

window.Swal = swal;

import Vue from "vue";

window.Vue = require("vue").default;
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  preLoad: 1.3, // Factor de carga anticipada (por defecto: 1.3)
  error: "/storage/img/no-image.jpg", // Imagen a mostrar en caso de error de carga
  loading: "/storage/img/logo_animado.gif", // Imagen de carga mientras se carga la imagen real
  attempt: 3, // Número de intentos de carga (por defecto: 3)
  throttleWait: 500, // Tiempo de espera en milisegundos antes de cargar imágenes adicionales durante el desplazamiento (por defecto: 500)
  observer: true, // Habilitar el uso de Intersection Observer para detectar cuando las imágenes son visibles en la ventana (por defecto: true)
  observerOptions: {
    rootMargin: "0px", // Margen adicional alrededor del área de visualización del observador (por defecto: '0px')
    threshold: 0.1, // Umbral de visibilidad para el observador (por defecto: 0.1)
  },
  filter: {
    filter: (listener, options) => {
      if (listener.src === null) {
        return "/storage/img/no-image.jpg";
      }
      return listener.src;
    },
  },
});

Vue.config.devtools = true;
Vue.config.productionTip = false;

import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

import VueAutosuggest from "vue-autosuggest";

Vue.use(VueAutosuggest);

// Registrar componentes de Vue.
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

// import Select2Component
//import Select2 from 'v-select2-component';
//Vue.component('Select2', Select2);

Vue.component("mtps-component", require("./components/MtpsComponent.vue"));
Vue.component("piezas-component", require("./components/PiezasComponent.vue"));
Vue.component(
  "example-component",
  require("./components/ExampleComponent.vue").default
);
Vue.component("vue-slick-carousel", VueSlickCarousel);

Vue.component(
  "banner-component",
  require("./components/cotizador-estandar/BannerComponent.vue").default
);
Vue.component(
  "cart-component",
  require("./components/cotizador-estandar/CartComponent.vue").default
);
Vue.component(
  "cart-item-component",
  require("./components/cotizador-estandar/CartItemComponent.vue").default
);
Vue.component(
  "input-with-icon",
  require("./components/InputWithIconComponent.vue").default
);
Vue.component(
  "custom-select",
  require("./components/CustomSelectComponent.vue").default
);
Vue.component(
  "custom-tags",
  require("./components/CustomTagsComponent.vue").default
);
Vue.component(
  "products-list",
  require("./components/cotizador-estandar/ProductsListComponent.vue").default
);
Vue.component(
  "product-item",
  require("./components/cotizador-estandar/ProductItemComponent.vue").default
);
Vue.component(
  "modal-component",
  require("./components/ModalComponent.vue").default
);
Vue.component(
  "input-number",
  require("./components/InputNumberComponent.vue").default
);
Vue.component(
  "header-component",
  require("./components/cotizador-personalizado/HeaderComponent.vue").default
);
Vue.component(
  "personalizado-banner",
  require("./components/cotizador-personalizado/BannerComponent.vue").default
);
Vue.component(
  "personalizado-products-list",
  require("./components/cotizador-personalizado/ProductsListComponent.vue")
    .default
);
Vue.component(
  "personalizado-product-item",
  require("./components/cotizador-personalizado/ProductItemComponent.vue")
    .default
);

Vue.component(
  "modal-editar-material",
  require("./components/ModalEditarMaterial.vue").default
);

import IndustriaFactory from "./industria/industria-factory";

Vue.filter("truncarTexto", function (texto, longitud) {
  if (texto && texto.length > longitud) {
    return texto.substring(0, longitud) + "...";
  } else {
    return texto;
  }
});

Vue.filter("redondearDosDecimales", function (valor) {
  if (isNaN(valor)) return valor; // Si no es un número, retorna el valor sin cambios
  return Math.round(valor * 100) / 100; // Redondea a dos decimales
});

/*  Usar assets en vue  */
Vue.mixin({
  methods: {
    asset: (path) => `${window._asset}/${path}`,
    toPrice: (number) =>
      number.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    getIndustry: (tipo, priceList) => {
      const industriaFactory = new IndustriaFactory();
      return industriaFactory.createIndustria(tipo, priceList);
    },
    parseProducts(products) {
      return products.map(
        ({
          id,
          tipo_id: tipoId,
          tipo,
          imagen: image,
          descripcion: description,
          precio: price,
          alto: height,
          ancho: width,
          profundidad: depth,
          nombre: name,
          hechoEn: madeIn,
          garantia: guarantee,
        }) => ({
          id,
          tipoId,
          tipo,
          image,
          description,
          price: Number(price),
          fullTextSearch:
            `${description}${price}${tipo}${id}${name}`.toLowerCase(),
          height,
          width,
          depth,
          name,
          madeIn,
          guarantee,
        })
      );
    },
  },
});

window.Toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

window.onload = function () {
  const container = document.querySelector("#app");
  const ps = new PerfectScrollbar(container, {
    wheelSpeed: 1,
    wheelPropagation: false,
    minScrollbarLength: 20,
  });
  window.perfectScrollbar = ps;
};

const localStorage = window.localStorage;

window.newQuote = 0;
