<template>
  <div class="row products-list">
    <personalizado-product-item
      v-for="(premium, i) in items"
      :key="i"
      :premium="premium"
    ></personalizado-product-item>
  </div>
</template>

<script>
export default {
  props: ["items"],
};
</script>

<style scoped>
.products-list {
  margin-top: 40px;
  max-width: 100%;
  max-height: 927px;
  overflow-y: auto;
}

.row {
  margin: 0;
}

p {
  display: flex;
  justify-content: center;
  width: 205.39px;
  height: 25.34px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #626262;
}
</style>