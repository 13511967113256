var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row products-list" },
    _vm._l(_vm.products, function (product, i) {
      return _c("product-item", {
        key: i,
        attrs: { product: product },
        on: { click: _vm.handleProductClicked },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }