<style scoped>
.product-item {
  box-shadow: none;
  max-width: 348px;
  height: 443px;
  margin-bottom: 41px;
  background: #f6f6f6;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  cursor: pointer;
  padding-block-start: 34px;
}

.price {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #121212;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-img-top {
  max-height: 230px;
  width: auto;
  margin: 0 auto;
}
.card-body {
  background-color: #fff;
}
</style>

<template>
  <div class="col col-12 col-md-6 col-lg-4 col-xl-3" @click="handleOnClick">
    <div class="card product-item">
      <img
        :src="asset(`/storage/modulos/${product.image}`)"
        class="card-img-top"
        :alt="product.name"
      />
      <div class="card-body">
        <p class="card-text description">
          {{ product.description }}
        </p>
        <p class="card-text price">${{ toPrice(product.price) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product"],
  methods: {
    handleOnClick() {
      this.$emit("click", this.product.id);
    },
  },
};
</script>