var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table", staticStyle: { "font-size": "0.8em" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.materiales, function (material, index) {
          return _c("tr", { attrs: { "track-by": "index" } }, [
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: material.material_id,
                      expression: "material.material_id",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "material_id[]" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          material,
                          "material_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.setDescripcion(
                          this.materiales[index].material_id,
                          index
                        )
                      },
                    ],
                  },
                },
                _vm._l(_vm.materialist, function (item) {
                  return _c("option", { domProps: { value: item.value } }, [
                    _vm._v(_vm._s(item.label)),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: material.descripcion_id,
                      expression: "material.descripcion_id",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "psedescripcion[]" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        material,
                        "descripcion_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.descripciones[index], function (descripcion) {
                  return _c(
                    "option",
                    { domProps: { value: descripcion.value } },
                    [_vm._v(_vm._s(descripcion.label))]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: material.largo,
                    expression: "material.largo",
                  },
                ],
                staticClass: "form-control form-control-sm text-uppercase",
                attrs: {
                  type: "text",
                  name: "pselargo[]",
                  value: "",
                  autocomplete: "off",
                },
                domProps: { value: material.largo },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(material, "largo", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: material.ancho,
                    expression: "material.ancho",
                  },
                ],
                staticClass: "form-control form-control-sm text-uppercase",
                attrs: {
                  type: "text",
                  name: "pseancho[]",
                  value: "",
                  autocomplete: "off",
                },
                domProps: { value: material.ancho },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(material, "ancho", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: material.espesor,
                      expression: "material.espesor",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "pseespesor[]" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        material,
                        "espesor",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "12" } }, [_vm._v("12")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "15" } }, [_vm._v("15")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "16" } }, [_vm._v("16")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "18" } }, [_vm._v("18")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "19" } }, [_vm._v("19")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: material.largo_izq,
                      expression: "material.largo_izq",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "pselargo_izq[]" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        material,
                        "largo_izq",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "0.45" } }, [_vm._v("0.45")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: material.largo_der,
                      expression: "material.largo_der",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "pselargo_der[]" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        material,
                        "largo_der",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "0.45" } }, [_vm._v("0.45")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1.00" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2.00" } }, [_vm._v("2")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: material.ancho_sup,
                      expression: "material.ancho_sup",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "pseancho_sup[]" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        material,
                        "ancho_sup",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "0.45" } }, [_vm._v("0.45")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: material.ancho_inf,
                      expression: "material.ancho_inf",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { name: "pseancho_inf[]" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        material,
                        "ancho_inf",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "0.45" } }, [_vm._v("0.45")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: material.mec1,
                    expression: "material.mec1",
                  },
                ],
                staticClass: "form-control form-control-sm text-uppercase mb-1",
                attrs: {
                  type: "text",
                  name: "psemec1[]",
                  value: "",
                  autocomplete: "off",
                },
                domProps: { value: material.mec1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(material, "mec1", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: material.mec2,
                    expression: "material.mec2",
                  },
                ],
                staticClass: "form-control form-control-sm text-uppercase mb-1",
                attrs: {
                  type: "text",
                  name: "psemec2[]",
                  value: "",
                  autocomplete: "off",
                },
                domProps: { value: material.mec2 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(material, "mec2", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: material.cantidad,
                    expression: "material.cantidad",
                  },
                ],
                staticClass: "form-control form-control-sm text-right",
                attrs: {
                  type: "number",
                  name: "psecantidad[]",
                  value: "",
                  min: "1",
                },
                domProps: { value: material.cantidad },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(material, "cantidad", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm._m(1, true),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "font-weight-bold" }, [
      _c("tr", [
        _c("td", [_vm._v("Material")]),
        _vm._v(" "),
        _c("td", [_vm._v("Descripcion")]),
        _vm._v(" "),
        _c("td", [_vm._v("Largo")]),
        _vm._v(" "),
        _c("td", [_vm._v("Ancho")]),
        _vm._v(" "),
        _c("td", [_vm._v("Espesor")]),
        _vm._v(" "),
        _c("td", [_vm._v("LargoIZQ")]),
        _vm._v(" "),
        _c("td", [_vm._v("LargoDER")]),
        _vm._v(" "),
        _c("td", [_vm._v("AnchoSUP")]),
        _vm._v(" "),
        _c("td", [_vm._v("AnchoINF")]),
        _vm._v(" "),
        _c("td", { attrs: { width: "8%" } }, [_vm._v("Mec1")]),
        _vm._v(" "),
        _c("td", { attrs: { width: "8%" } }, [_vm._v("Mec2")]),
        _vm._v(" "),
        _c("td", { attrs: { width: "5%" } }, [_vm._v("Cantidad")]),
        _vm._v(" "),
        _c("td", [
          _c(
            "a",
            {
              staticClass: "btn btn-link float-right",
              attrs: { href: "#", title: "Agregar" },
            },
            [_c("i", { staticClass: "fas fa-plus" })]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c(
        "a",
        {
          staticClass: "btn btn-link text-danger",
          attrs: { href: "#", title: "Eliminar" },
        },
        [_c("i", { staticClass: "fas fa-minus" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }