var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider-container" },
    _vm._l(_vm.images, function (image, i) {
      return _c("img", {
        key: i,
        staticClass: "slider-image",
        attrs: { src: image, alt: image },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }