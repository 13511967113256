var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-container" }, [
    _c("img", { attrs: { src: "/storage/img/input-icon.png", alt: "icon" } }),
    _vm._v(" "),
    _c("label", [
      _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        attrs: { type: "text", placeholder: _vm.placeholder },
        domProps: { value: _vm.inputValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.inputValue = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }