
<style scoped>
.product-container {
  display: flex;
  flex-direction: column;
  padding: 51px;
}

.product-header {
  display: flex;
}

.icon-square {
  display: grid;
  place-content: center;
  background: #1d518a;
  width: 66px;
  height: 66px;
}

.product-title {
  display: flex;
  flex: 11 1 0;
}

.text-content {
  margin-left: 20px;
}

.text-content h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #121212;
}

.text-content p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #121212;
}

.close {
  flex: 1 1 0;
  align-self: flex-start;
}

.product-content {
  display: flex;
}

.product-content img {
  background-size: cover;
  width: 360px;
  height: auto;
}

.product-info {
  margin-left: 39px;
}

.product-info p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #121212;
}

.price {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #121212;
}

.product-info button {
  background: linear-gradient(90deg, #00a881 0%, #00a881 100%);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.81485px 17.3333px 8.81485px 13.623px;
  gap: 8.3px;
  margin-top: 18px;
}

.modal-content {
  border-radius: 10px;
}

.quantity {
  margin-top: 14px;
}
</style>


<template>
  <div class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" v-if="product">
        <div class="product-container">
          <div class="product-header">
            <div class="product-title">
              <div class="icon-square">
                <img src="/storage/img/iconCuadrado.png" alt="..." />
              </div>
              <div class="text-content">
                <h2>{{ product.name }}</h2>
                <p>{{ product.tipo }}</p>
              </div>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span class="material-symbols-outlined"> close </span>
            </button>
          </div>
          <div class="product-content">
            <img
              :src="asset(`/storage/modulos/${product.image}`)"
              :alt="product.name"
            />
            <div class="product-info" v-if="product.description">
              <p>
                {{ product.description }}
              </p>
              <p v-if="product.height || product.width || product.depth">
                Dimensiones: {{ product.height }}x{{ product.width }}x{{
                  product.depth
                }}mm
              </p>
              <p v-if="product.madeIn">Hecho en {{product.madeIn}}</p>
              <p v-if="product.guarantee">Garantía: {{product.guarantee}}</p>
              <div class="price">${{ toPrice(product.price) }}</div>
              <input-number class="quantity" min="1" :value="quantity" @change="handleOnChangeQuantity"></input-number>
              <button @click="handleOnAddProduct">Agregar producto</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      quantity: 1,
    };
  },
  methods: {
    handleOnAddProduct() {
      this.$emit("product-added", {
        ...this.product,
        quantity: this.quantity,
      });
      this.quantity = 1;
    },
    handleOnChangeQuantity(value) {
      this.quantity = value;
    } 
  },
  watch: {
    product(newProduct) {
      this.quantity = newProduct?.quantity || 1;
    },
  },
};
</script>
  