var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tags-container" }, [
    _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tags-list" },
      [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            attrs: {
              hidden: "",
              id: "Todas",
              type: "radio",
              name: _vm.label.toLowerCase(),
              checked: "",
            },
            domProps: {
              value: undefined,
              checked: _vm._q(_vm.inputValue, undefined),
            },
            on: {
              change: function ($event) {
                _vm.inputValue = undefined
              },
            },
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "Todas" } }, [_vm._v("Todas")]),
        ]),
        _vm._v(" "),
        _vm._l(_vm.options, function (option, i) {
          return _c("div", { key: i }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              attrs: {
                hidden: "",
                id: option["label"],
                type: "radio",
                name: _vm.label.toLowerCase(),
              },
              domProps: {
                value: option,
                checked: _vm._q(_vm.inputValue, option),
              },
              on: {
                change: function ($event) {
                  _vm.inputValue = option
                },
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: option["label"] } }, [
              _vm._v(_vm._s(option["label"])),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }