<template>
  <div class="input-number-container">
    <button
      class="control minus"
      @click="() => changeNumber(-1)"
      :disabled="value === +min"
    ></button>
    <input type="number" :value="value" :min="min" />
    <button class="control plus" @click="() => changeNumber(1)"></button>
  </div>
</template>

<script>
export default {
  props: ["min", "value"],
  methods: {
    changeNumber(_value) {
      this.$emit("change", this.value + _value);
    },
  },
};
</script>

<style scoped>
.input-number-container {
  display: flex;
  align-items: center;
  column-gap: 9px;
}

/* Chrome, Safari, Edge, Opera */
.input-number-container input::-webkit-outer-spin-button,
.input-number-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-number-container input[type="number"] {
  -moz-appearance: textfield;
}

.input-number-container input[type="number"] {
  width: 24px;
  height: 24px;
  border: none;
  background-color: #e2e2e2;
  padding: 0;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 17.795px;
  line-height: 24px;
  color: #000000;
}

button.control {
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-color: #fff;
}

button.control:disabled {
  cursor: not-allowed;
}

button.control.minus::before,
button.control.plus::before {
  content: "-";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 18px;
  line-height: 16px;
}

button.control.plus::before {
  content: "+";
}
</style>