var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-container", class: _vm.expand && "expanded" },
    [
      _c("div", { staticClass: "cart-resume" }, [
        _c("h6", [_vm._v("Mi pedido")]),
        _vm._v(" "),
        _c("div", { staticClass: "subtotal" }, [
          _c("p", [_vm._v("Subtotal")]),
          _vm._v(" "),
          _c("p", { staticClass: "amount" }, [
            _vm._v("$" + _vm._s(_vm.toPrice(_vm.subtotal || 0))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-link save",
            attrs: { disabled: _vm.isEmpty },
            on: { click: _vm.handleOnSaveClicked },
          },
          [_vm._v("\n      Guardar cotización\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-canvas pay",
            attrs: { disabled: _vm.isEmpty },
            on: { click: _vm.handleOnProceedToCheckoutClicked },
          },
          [_vm._v("\n      Proceder al pago\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-items" },
        _vm._l(
          _vm.products,
          function (
            {
              id,
              price,
              quantity,
              image,
              name,
              description,
              height,
              width,
              depth,
              madeIn,
              guarantee,
            },
            i
          ) {
            return _c("cart-item-component", {
              key: i,
              staticClass: "cart-item",
              attrs: {
                id: id,
                price: price,
                quantity: quantity,
                image: image,
                name: name,
                description: description,
                height: height,
                width: width,
                depth: depth,
                madeIn: madeIn,
                guarantee: guarantee,
                size: _vm.expand ? "cart-item-large" : "cart-item-small",
              },
              on: {
                removed: _vm.handleOnProductRemoved,
                edited: _vm.handleOnProductEdited,
              },
            })
          }
        ),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }