<template>
  <div>
    <div class="sticky-container background-inherit">
    <span class="float-left">
        <span class="ml-4">
            <a class="btn btn-sm btn-primary text-white py-2"
               data-toggle="modal"
               data-target="#editarMaterialModal"
               @click="$emit('initEdit')"
            >Editar</a>
        </span>
    </span>
    </div>
    <div class="modal fade" id="editarMaterialModal" tabindex="-1" role="dialog" :aria-labelledby="'modalLabel'" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" :id="modalLabel">{{ modalTitle }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar" @click="limpiarEditGlobal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="container">
                <span class="ml-3 mt-3 mb-1 subtitulo-color"><strong>Selección de Materiales</strong></span>
                <div class="row">
                  <div v-for="(material, index) in materials" :key="index" class="col-md-3 form-group card p-2">
                    <label :for="material.id" class="d-block">
                      <img class="mb-2" :src="'/storage/img/no-image.jpg'" title="No Imagen" alt="No Imagen" width="100px">
                      <span class="d-block">{{ material.name }}</span>
                    </label>
                    <select
                        :name="material.id"
                        :id="material.id"
                        class="form-control form-control-sm select2 w-100"
                        v-model="dataGlobalEdit[material.type].board"
                    >
                      <option value="" disabled selected>Selección</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="limpiarEditGlobal">Cerrar</button>
            <button type="button" class="btn btn-primary">Guardar cambios</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalLabel: {
      type: String,
      required: false,
    },
    modalTitle: {
      type: String,
      required: false,
    },
    materials: {
      type: Array,
      required: false,
    },
    dataGlobalEdit: {
      type: Object,
      required: false,
    },
  },
  methods: {
    limpiarEditGlobal() {
      // Lógica para limpiar los datos en dataGlobalEdit
    },
  },
};
</script>